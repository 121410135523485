import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { loginRequestUrl, makeLoginRequest } from '../utils/auth';
import { getIn } from '../utils/functional';
import { redirect, clearServiceWorkerCache, clearPluginOfflineCache } from '../utils/redirect';
import { refreshSessionId } from '../utils/session_id';
import { getInUserState, dissocInUserState } from '../utils/user_state';
import { emailIsValid } from '../utils/validate';

import Layout from '../components/layout';
import { labelNameTag, inputTag } from '../components/login/input';

import { colors } from '../components/colors';
import * as styles from '../components/login/login.module.css';
import * as buttonStyles from '../components/button.module.css';
import * as linkStyles from '../components/link.module.css';

import { RenderHead } from "../components/head";
const title = 'Log In';
export const Head = () => RenderHead({ title });



const redirectDestination = '/';


const errorMessage = {
    email: 'Please enter a valid email address',
    passwordWasChanged: 'Your password has been updated',
    notFound: 'Log-in information was incorrect, please try again',
    connectivity: 'No internet connectivity. Please connect and try again',
    server: function ()
    {
        return (
            <div>
              <span>Server error. Please try again later or contact us at</span>

              <OutboundLink
                className={ linkStyles.errorLink }
                href={ 'mailto:help@6pages.com' } >
                help@6pages.com
              </OutboundLink>

              <span>for assistance</span>
            </div>
        );
    }()
};


const systemMessage = function ()
{
    const stateMessageToErrorMessageK = {
        'passwordChanged' : 'passwordWasChanged'
    };
    const userStateMessages = getInUserState(['messages', 'login']);
    
    if ( typeof window !== 'undefined'
         && userStateMessages
         && typeof userStateMessages === 'object'
         && Object.keys(userStateMessages).length > 0 )
    {
        // clear state
        dissocInUserState(['messages', 'login']);
        const userStateMsg = Object.keys(userStateMessages)[0];
        const errorMsgK = stateMessageToErrorMessageK[userStateMsg];
        return errorMessage[errorMsgK];
    }
    return '';
};


//
// helpers

const chain = function (...args) {
    let fns = arguments;
    return () => {
        return Object
            .values(fns)
            .map( fn => fn.apply(null) );
    };
};





//
// component

class LoginForm extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            email: { value: '' },
            password: { value: '' },
            error: { text: '' },
            inFlight: false
        };

        this.resetError = this.resetError.bind(this);
        this.resetInFlight = this.resetInFlight.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);        
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    resetError ()
    {
        if ( this.state.error.text.length > 0 )
            this.setState({ error : { text: '' }});
    }

    resetInFlight ()
    {
        this.setState({ inFlight: false });
    }
    
    handleChangeEmail (event)
    {
        this.setState({ email: { value: event.target.value }});
        this.resetError();
    }

    handleChangePassword (event)
    {
        this.setState({password: {value: event.target.value}});
        this.resetError();
    }
    
    handleSubmit (event)
    {
        event.preventDefault();

        if ( this.state.inFlight ) return;    // request already in flight

        let errorFnGen = function (m) {
            return function (msg) {
                this.setState({ error: { text: m }});
            }.bind(this);
        }.bind(this);


        if ( ! emailIsValid(this.state.email.value) )
            this.setState({ error : { text: errorMessage.email }});
        else
        {
            this.setState({ inFlight: true });
            const notFoundErrorFn = chain(this.resetInFlight, errorFnGen(errorMessage.notFound));
            
            makeLoginRequest(
                this.state.email.value,
                this.state.password.value,
                loginRequestUrl.user,
                function (response)
                {   // on success!
                    const r = JSON.parse(response);
                    const tokens = getIn( r, ['data', 'sessionToken'] );

                    if ( Array.isArray(tokens) && tokens.length > 0 )
                    {
                        clearServiceWorkerCache();
                        clearPluginOfflineCache();
                        redirect(redirectDestination);
                    }
                    else
                        notFoundErrorFn.apply(null, []);
                },
                notFoundErrorFn,
                chain(this.resetInFlight, errorFnGen(errorMessage.server)),
                chain(this.resetInFlight, errorFnGen(errorMessage.connectivity))
            );
        }
    }
    
    render ()
    {
        return (
            <React.Fragment>
              <form
                onSubmit={this.handleSubmit} >

                <div
                  className={ styles.container }
                  style={{
                      display: `flex`,
                      flexDirection: `column`,
                      justifyContent: `flex-start`,
                      alignItems: `flex-start`
                  }} >

                  <label
                    style={{
                        width: `inherit`,
                        margin: `50px 0 0`,
                    }} >

                    { labelNameTag('Email address') }
                    { inputTag( 'text', 'EmailAddress', this.state.email.value, this.handleChangeEmail ) }

                  </label>

                  
                  <label
                    style={{
                        width: `inherit`,                        
                        margin: `50px 0 0`
                    }} >

                    { labelNameTag('Password') }
                    { inputTag( 'password', 'Password', this.state.password.value, this.handleChangePassword ) }
                    
                  </label>
                  
                </div>

                <div
                  style={{
                      margin: `9px 0 0`,
                      fontSize: `12px`,
                      fontStyle: `italic`,
                      letterSpacing: `0.12px`,
                      color: colors.visibleGrey
                  }}>

                  <span>Forgot password?</span>

                  <Link
                    to={ `/password/forgot` }
                    style={{
                        margin: `0 3px 0`,
                        color: colors.visibleGrey,
                        boxShadow: `none`,
                        textDecoration: `solid underline 1px`
                    }} >
                    Reset
                  </Link>
                  
                </div>

                <div
                  name='MessageForm'
                  className={ styles.container }
                  style={{
                      margin: `30px 0 0`,
                      minHeight: `48px`,
                      textAlign: `center`,
                      fontSize: `13px`,
                      fontStyle: `italic`,
                      letterSpacing: `0.13px`,
                      color: colors.red
                  }} >
                  { this.state.error.text }
                </div>

                <div
                  style={{
                      marginTop: `15px`,
                      width: `100%`,
                      display: `flex`,
                      flexDirection: `column`,
                      justifyContent: `center`,
                      alignItems: `center`
                  }} >
                  
                  <input
                    type='submit'
                    aria-label='Log in'
                    value='Log In'
                    disabled={ this.state.inFlight }
                    className={ buttonStyles.submit }
                    style={{
                        width: `203px`,
                        height: `48px`
                    }} />
                  
                </div>
                
              </form>
            </React.Fragment>
        );
    }
}

class Login extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            message: { system: '' }
        };
    }
    
    componentDidMount ()
    {
        var m = this.state.message;
        m.system = systemMessage();
        this.setState(m);
        refreshSessionId();
    }
    
    render()
    {   
        return (
            <Layout
              location={ this.props.location }
              removeHorizontalPadding={ true } >

              <div
                style={{
                    margin: `70px auto 90px`,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `flex-start`,
                    alignItems: `center`
                }} >

                <div
                  style={{
                      margin: 0,
                      fontFamily: `"Overpass", sans-serif`,
                      fontSize: `40px`,
                      fontWeight: `bold`,
                      lineHeight: `1.13`,
                      color: colors.green
                  }} >
                  { title }
                </div>

                <div
                  name='MessageGlobal'
                  style={{
                      margin: `15px 0 0`,
                      fontSize: `15px`,
                      lineHeight: `1.87`,
                      letterSpacing: `0.15px`,
                      textAlign: `center`,
                      color: colors.orange
                  }} >
                  { this.state.message.system }
                </div>
                
                
                <LoginForm />

                <div
                  style={{
                      margin: `10px 0 40px`,
                      fontSize: `14px`,
                      lineHeight: `1.79`,
                      letterSpacing: `0.14px`,
                      color: colors.black
                  }} >

                  <span>
                    Not a Member?
                  </span>

                  <Link
                    to={ `/join/` }
                    className={ linkStyles.greenLink }
                    style={{
                        margin: `0 7px 0`,
                        fontFamily: `"Overpass", sans-serif`,
                        fontSize: `14px`,
                        fontWeight: `600`,
                        lineHeight: `2.57`,
                        letterSpacing: `0.35px`
                    }} >
                    Join
                  </Link>
                  
                </div>
                
              </div>
            </Layout>
        );
    }
}


export default Login;
